










































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import ButtonType from '@/assets/buttonTypes';

@Component({
  components: {
    TextHeader,
    TextInput,
    RoundedButtonFilled,
  },
})
export default class KeycloakClientTestForQM extends Vue {
  private Header = Header;
  private clientId: string = '';
  private clientSecret: string = '';
  private success: boolean = false;
  private clientSecretValid: boolean = false;
  private clientSecretNotValid: boolean = false;
  private ButtonType = ButtonType;

  private mounted() {
    if (this.$route.query.clientId) {
      this.success = true;
      this.clientId = this.$route.query.clientId as string;
    }
  }

  private login() {
    window.location.href = `${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/innovamo/protocol/openid-connect/auth?client_id=${this.clientId}&response_type=code&redirect_uri=${process.env.VUE_APP_FRONTEND_URL}/keycloakClientTestForQM?clientId=${this.clientId}&withOutRegister=true`;
  }

  private async getToken() {
    this.clientSecretValid = false;
    this.clientSecretNotValid = false;

    try {
      const response = await fetch(`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/innovamo/protocol/openid-connect/token`, {
        method: 'POST',
        body: new URLSearchParams({
          'client_id': this.clientId,
          'client_secret': `${this.clientSecret}`,
          'grant_type': 'authorization_code',
          'code': this.$route.query.code as string,
          'redirect_uri': 'http://localhost:8080',
        }),
      });
      console.log(response);
      if (response.status === 400) {
        this.clientSecretValid = true;
      }

      if (response.status === 401) {
        this.clientSecretNotValid = true;
      }
      setTimeout(() => {
        this.clientSecretValid = false;
        this.clientSecretNotValid = false;
      }, 5000);
    } catch (error) {
      console.log(error);
    }

  }
}
